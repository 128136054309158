/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useEffect, useRef, useContext, useState } from 'react';
import {
  Config,
  EditableComponent,
  MappedComponentProperties,
  ResponsiveGrid,
} from '@adobe/aem-react-editable-components';
import { useQuery } from '@apollo/client';
import { PageParamsContext } from '../../modules/context/PageContext';
import { useServiceStore, serviceStore } from '../../modules/store/serviceStore';
import { headerDetails } from '../../modules/graphql/index';
import { convertPhoneNumberToDE } from '../../modules/utils/common';
import { LocalHeaderProps } from './LocalHeader.types';
import { StyledLocalHeader } from './LocalHeader.styles';
import { mapURL, baiduMapURL, cna, experienceFragmentComponentTypes } from '../../modules/utils/constants/constants';
import { Image, Link } from '@marriott/mi-ui-library';
import { AccuWeather } from '../AccuWeather';
import {
  chineseLocations,
  merchandisingContectID,
  merchandisingLuxuryBrand,
  localOffersContectID,
} from '../../modules/utils/constants/constants';
import { localheaderMockUXL } from './__mock__/LocalHeaderMock';

const { NEXT_PUBLIC_AEM_SITE } = process.env;

declare global {
  interface Window {
    BMap: any;
  }
}

export const LocalHeader: FC<LocalHeaderProps> = pageProps => {
  const serviceData = useServiceStore((state: serviceStore) => state.serviceData);
  let offsetTop = 0;
  let lastScrollTop = 0;
  let globalNavHeight = 0;
  const linkedTargetElements: Array<{
    targetElement: Element | null;
    linkElement: null;
  }> = [];
  const [hideMap, setHideMap] = useState(false);
  const [hideNavigation, setHideNavigation] = useState(false);
  const singleRefLocalheaderTop = useRef<HTMLDivElement>(null);
  const singleRefAnchorList = useRef<HTMLDivElement>(null);
  let listRefStickyNavLinks: NodeListOf<HTMLElement>;
  const [l2NavLinks, setL2NavLinks] = useState<JSX.Element[]>([]);

  const isServer = !(typeof window != 'undefined' && window.document);
  const { preProcessorResponse, sessionID, requestId, lang } = useContext(PageParamsContext);
  const { brandCode } = preProcessorResponse ?? {};
  let { data } = useQuery(headerDetails, {
    fetchPolicy: isServer ? 'network-only' : 'cache-first',
    variables: {
      propertyId: preProcessorResponse?.marsha ?? '',
      filter: 'PHONE',
    },
    context: {
      headers: {
        //Update the variables of AriesReservation for ppv5
        'accept-language': lang ?? 'en-US',
        'x-b3-traceId': sessionID ?? 'fallback-token',
        'x-b3-spanId': requestId !== '' ? requestId : `${Date.now()}`,
        'correlation-id': sessionID ?? 'fallback-token',
      },
    },
    skip: pageProps.isAuthorMode,
  });

  if (pageProps.isAuthorMode) {
    data = localheaderMockUXL;
  }
  const [isLuxuriousBrand, setIsLuxuriousBrand] = useState<boolean>(false);

  useEffect(() => {
    document.querySelector('.global-nav')?.classList.add('page-wrap');
    if (pageProps?.hideMap) {
      hideFunction(pageProps?.selectedStatesMap, setHideMap);
    }
    if (pageProps?.hideL2Navigation) {
      hideFunction(pageProps?.selectedStatesL2Navigation, setHideNavigation);
    }
    offsetTop = singleRefLocalheaderTop?.current?.clientHeight ?? 0;
    globalNavHeight = document?.querySelector('.m-global-nav')?.clientHeight ?? 0;
    window.addEventListener('scroll', pageScroll, { passive: true });
    document.addEventListener('BAIDU_SCRIPT_LOADED', function () {
      if (window?.BMap && window.BMap?.Map) {
        getBaiduMap();
      }
      document.removeEventListener('BAIDU_SCRIPT_LOADED', getBaiduMap);
    });

    const location =
      window.dataLayer && window.dataLayer['browser_akamai_loc_country']
        ? (window.dataLayer['browser_akamai_loc_country'] as string)
        : '';
    if (location && chineseLocations.includes(location)) {
      document.querySelector('body')?.classList.add('chinese-page');
      baiduMap();
    }

    //script to scroll Accessibility Features and Sustainability link accordian into view - OSD-4801
    const linkArray = [
      { link: '#access', target: '[data-reference-hotel-info="access"]' },
      { link: '#sustainability', target: '[data-reference-amenities="sustainability"]' },
    ];
    const accWrapper = document.querySelector('div[data-id="property-details"]');
    // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
    const propertyAcc = (accWrapper && accWrapper?.querySelectorAll('.property-details-accordion')) ?? [];

    const removeAllActiveAcc = (): void => {
      propertyAcc?.forEach(function (acc) {
        acc.classList.remove('active');
      });
    };

    linkArray.forEach(item => {
      const infoLink = document.querySelectorAll(`a[href='${item.link}']`)[0];
      if (infoLink) {
        infoLink.setAttribute('role', 'button');
        infoLink.setAttribute('href', '#');
        const referenceInfo = document.querySelectorAll(item.target)[0];
        infoLink.addEventListener('click', e => {
          e.preventDefault();
          setTimeout(() => {
            removeAllActiveAcc();
            referenceInfo.classList.add('active');
            accWrapper?.scrollIntoView();
          }, 200);
        });
      }
    });
  }, []);

  useEffect(() => {
    let links: JSX.Element[] = [];
    pageProps?.navigationAttributes?.map((navigation, index) => {
      if (document.getElementById(navigation.componentId)) {
        //to hide merchandising component
        if (!(isLuxuriousBrand && navigation.componentId === merchandisingContectID)) {
          //to hide Nearby Things To Do component
          if (
            navigation.componentId !== localOffersContectID ||
            (navigation.componentId === localOffersContectID && document.querySelector('.app-js__nearby-outlet'))
          )
            links = [
              ...links,
              <li key={`${navigation.componentId}-${index}`} className="py-0 mr-3 pr-3 my-2">
                <Link
                  linkHref={`#${navigation.componentId}`}
                  linkClassName="t-font-s pt-0 mr-0 mr-md-1 local-header__menu-bottom-link links mb-1"
                >
                  {navigation?.label}
                </Link>
              </li>,
            ];
        }
      }
    });
    setL2NavLinks(links);
  }, [serviceData?.servicesLoaded, isLuxuriousBrand]);

  useEffect(() => {
    listRefStickyNavLinks = document.querySelectorAll('.local-header__menu-bottom-link');
    listRefStickyNavLinks.forEach((link: any) => {
      linkedTargetElements.push({
        targetElement: document.querySelector(`[id="${link.hash.substr(1)}"]`),
        linkElement: link,
      });
      link.addEventListener('click', linkClicked, link);
    });
  }, [l2NavLinks]);

  useEffect(() => {
    if (brandCode && merchandisingLuxuryBrand.includes(brandCode)) {
      setIsLuxuriousBrand(true);
    }
  }, [brandCode]);

  const baiduMap = async () => {
    try {
      const response = await fetch('/services/marriott-hws/getBaiduMapsApiKey.json', {
        method: 'GET',
        headers: {
          'Content-Type': 'text/plain',
        },
      });
      if (response.status === 200) {
        const data = await response.json();
        const apiKey = atob(data?.apiKey);
        const script = document.createElement('script');
        script.src = `https://api.map.baidu.com/getscript?v=2.0&ak=${apiKey}`;
        script.id = 'baidu-script';
        script.defer = true;

        // Append the 'script' element to 'head'
        document.head.appendChild(script);
        script.addEventListener('load', function (e) {
          e.preventDefault();
          if (window?.BMap && window?.BMap.Map) {
            const event = new CustomEvent('BAIDU_SCRIPT_LOADED', { detail: { loaded: true } });

            document.dispatchEvent(event);
          }
        });
        console.log('response map', data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getBaiduMap = () => {
    const mapele = document.querySelector('.chineseContent.loading-map') as HTMLAnchorElement;
    const BMap = window?.BMap;
    if (mapele) {
      let latlng = [mapele.href];

      if (latlng[0].search('latlng=') >= 0) {
        const substr = latlng[0].substring(latlng[0].search('latlng='));
        latlng = substr ? substr.split('=') : [];
        latlng = latlng && latlng.length > 1 ? latlng[1].split(',') : [];
      }

      const lat = latlng && latlng.length > 1 ? latlng[0] : null;
      const long = latlng && latlng.length > 1 ? latlng[1] : null;

      if (lat && long) {
        const gPoint = new BMap.Point(long, lat);
        const translateCallback = (data: any) => {
          if (data.status === 0) {
            let href = mapele.href.split('?')[0];
            href = `${href}?latlng=${data.points[0].lat},${data.points[0].lng}`;
            mapele.href = href;
            mapele.classList.remove('loading-map');
          }
        };

        const convertor = new BMap.Convertor();
        const points = [];
        points.push(gPoint);
        convertor.translate(points, 3, 5, translateCallback);
      }
    }
  };

  const hideFunction = (
    selectedStatesArr: string | string[],
    setStateFunc: {
      (value: React.SetStateAction<boolean>): void;
      (value: React.SetStateAction<boolean>): void;
      (arg0: boolean): void;
    }
  ) => {
    if (preProcessorResponse?.isCNA) {
      if (selectedStatesArr?.includes(cna)) {
        setStateFunc(true);
      }
    }
    if (selectedStatesArr?.includes(preProcessorResponse?.state)) {
      setStateFunc(true);
    }
  };

  const pageScroll = (): void => {
    const stickyNav = singleRefLocalheaderTop?.current;
    const globalNav: any = document.querySelector('.m-global-nav');
    const currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
    if (!stickyNav) {
      return;
    }

    if (window.pageYOffset >= offsetTop) {
      singleRefAnchorList.current?.classList.add('sticky');
      if (currentScroll < lastScrollTop) {
        globalNav?.classList.add('sticky');
        singleRefAnchorList?.current?.classList.remove('top-sticky');
        if (globalNav) {
          globalNav.style.top = 0;
        }
      } else {
        globalNav?.classList.add('sticky');
        singleRefAnchorList?.current?.classList.add('top-sticky');
        if (globalNav) {
          if (currentScroll < 60) {
            globalNav.style.top = `-${currentScroll}px`;
          } else {
            globalNav.style.top = `-${globalNavHeight}px`;
          }
        }
      }
    } else {
      singleRefAnchorList?.current?.classList.remove('sticky');
      globalNav?.classList.remove('sticky');
      singleRefAnchorList?.current?.classList.remove('top-sticky');
      if (globalNav) {
        globalNav.style.top = 0;
      }
    }
    lastScrollTop = currentScroll;
    const stickOffset = stickyNav.offsetTop;

    if (window.pageYOffset >= stickOffset) {
      if (!stickyNav.classList.contains('sticky')) {
        stickyNav.classList.add('sticky');
      }
      linkedTargetElements.forEach((linkTarget: any) => {
        if (!linkTarget || !linkTarget.targetElement) {
          return;
        }
        const clientRects = linkTarget.targetElement.getBoundingClientRect();
        if (parseInt(clientRects.top) - 48 <= 0 && parseInt(clientRects.bottom) - 240 >= 0) {
          linkTarget.linkElement.classList.add('active');
          if (linkTarget.linkElement.getBoundingClientRect().right > window.innerWidth) {
            document
              ?.querySelector('.local-header .sticky-nav')
              ?.scrollTo(linkTarget.linkElement.getBoundingClientRect().left, 0);
          }
          linkTarget.linkElement.scrollIntoView();
        } else {
          linkTarget.linkElement.classList.remove('active');
        }
      });
    } else {
      if (stickyNav.classList.contains('sticky')) {
        stickyNav.classList.remove('sticky');
      }
    }
  };

  const linkClicked = (event: any) => {
    if (event.target.hash !== '') {
      event.preventDefault();
      const requiredElementHash = event.target.hash.substr(1);
      const requiredElement = document.querySelector(`[id="${requiredElementHash}"]`) as HTMLElement;
      const v1Offset = 0;
      //const localheader = document.querySelector('.local-header');
      // if (localheader.classList.contains('v2-local-header-wrapper')) {
      //   const v1offsetParent = requiredElement.offsetParent as HTMLElement;
      //   v1Offset = v1offsetParent?.offsetTop;
      // }
      const totalOffset = requiredElement?.offsetTop + v1Offset;
      const singleRefLocalheaderTopRef = singleRefLocalheaderTop.current;
      const singleRefAnchorListRef = singleRefAnchorList.current;

      if (window.scrollY < totalOffset) {
        if (
          singleRefLocalheaderTopRef &&
          singleRefLocalheaderTopRef?.getBoundingClientRect().top >= 0 &&
          singleRefAnchorListRef &&
          window.innerWidth >= 768
        ) {
          window.scrollTo(
            0,
            totalOffset - singleRefAnchorListRef?.clientHeight - singleRefLocalheaderTopRef?.clientHeight
          );
        } else if (
          singleRefLocalheaderTopRef &&
          singleRefLocalheaderTopRef.getBoundingClientRect().top >= 0 &&
          singleRefAnchorListRef &&
          window.innerWidth < 768
        ) {
          globalNavHeight = document?.querySelector('.m-global-nav')?.clientHeight ?? 0;
          window.scrollTo(
            0,
            totalOffset -
              singleRefAnchorListRef?.clientHeight -
              singleRefLocalheaderTopRef?.clientHeight +
              globalNavHeight
          );
        } else {
          if (singleRefAnchorListRef) {
            globalNavHeight = document?.querySelector('.m-global-nav')?.clientHeight ?? 0;
            window.scrollTo(0, totalOffset - singleRefAnchorListRef?.clientHeight - globalNavHeight);
          }
        }
      } else {
        if (singleRefAnchorListRef) {
          globalNavHeight = document?.querySelector('.m-global-nav')?.clientHeight ?? 0;
          window.scrollTo(0, totalOffset - singleRefAnchorListRef?.clientHeight - globalNavHeight);
        }
      }
    }
  };

  //Method for returning Responsive Grid for showing parsys in Author mode
  const AccuWeatherContainer = () => {
    return (
      <div data-testid="brand-container-component">
        <ResponsiveGrid
          gridClassNames={`col-12`}
          columnClassNames={{
            responsivegrid: `col-12`,
          }}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          pagePath={pageProps?.pagePath}
          itemPath={`${pageProps?.itemPath}/container-0`}
          config={{
            isEmpty: () => true,
            resourceType: 'mi-aem-common-spa/components/container',
          }}
        />
      </div>
    );
  };

  //Method for returning components to be rendering at end point URL
  const AccuWeatherContainerWrapper = (cardName: string, jsonData: any) => {
    const returnable: Array<any> = [];
    // eslint-disable-next-line no-prototype-builtins
    if (jsonData?.hasOwnProperty(cardName)) {
      const card = jsonData[cardName];
      const cardItems = card[':items'];
      for (const itemKey in cardItems) {
        // eslint-disable-next-line no-prototype-builtins
        if (cardItems?.hasOwnProperty(itemKey)) {
          const item = cardItems[itemKey];
          if (item[':type'] === experienceFragmentComponentTypes.accuWeather) {
            returnable.push(<AccuWeather {...item} />);
          } else {
            for (const i in item?.[':items']) {
              for (const j in item?.[':items']?.[i]) {
                for (const k in item?.[':items']?.[i]?.[j]) {
                  for (const l in item?.[':items']?.[i]?.[j]?.[k]) {
                    for (const m in item?.[':items']?.[i]?.[j]?.[k]?.[l]) {
                      const obj = item?.[':items']?.[i]?.[j]?.[k]?.[l]?.[m];
                      if (obj[':type'] === experienceFragmentComponentTypes.accuWeather) {
                        returnable.push(<AccuWeather {...obj} />);
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      return <div data-testid="brand-container-component-wrapper">{returnable}</div>;
    }
    return null;
  };

  const showAccuWeatherContainer = pageProps.isAuthorMode ? (
    <div>{Array.from({ length: 1 }, _ => AccuWeatherContainer())}</div>
  ) : (
    <div>
      {pageProps?.cqItemsOrder &&
        !hideNavigation &&
        AccuWeatherContainerWrapper(pageProps?.cqItemsOrder?.[0], pageProps?.cqItems)}
    </div>
  );

  return (
    <StyledLocalHeader data-component-name="o-ppv5-localheader">
      <div className="local-header" id="main">
        <div className="d-flex flex-wrap mx-md-4 px-md-2 mx-0">
          <div className="local-header__menu col-12 p-0">
            <div
              className="local-header__menu-top d-flex flex-wrap justify-content-between align-items-center mx-3 px-0 mx-md-0  my-0 my-md-1 my-xl-0"
              ref={singleRefLocalheaderTop}
            >
              <div className="local-header__menu-top-left d-flex flex-wrap py-3 align-items-center col-12 col-md-auto p-0 col col-xl">
                <a
                  href={pageProps?.homePage}
                  target={
                    preProcessorResponse?.sameTab || preProcessorResponse?.cna
                      ? '_self'
                      : pageProps?.openHotelLogoInNewTab === 'true'
                      ? '_blank'
                      : '_self'
                  }
                  rel="noreferrer"
                >
                  <Image
                    defaultImageURL={pageProps?.hotelLogoFile}
                    altText={pageProps?.logoAltText}
                    dynamic={true}
                    renditions={pageProps?.dynamicMedia?.renditions ?? []}
                    title={pageProps?.logoAltText || 'Hotel logo'}
                    loading="eager"
                    customClass="local-header__menu-top-left--logo"
                  ></Image>
                </a>
                <div className="ml-4 pl-0 pl-md-2 pl-xl-2 col">
                  <p className="local-header__menu-top-left--title pl-xl-1 pr-xl-1 pl-0 mb-0 t-subtitle-xl">
                    {data?.property?.basicInformation?.name}
                  </p>
                </div>
              </div>
              <div className="local-header__menu-top-right d-flex flex-wrap py-3 align-items-center justify-content-around col-9 col-md-auto px-0">
                <div className="local-header__menu-top-right-location d-flex align-items-center">
                  {!hideMap && data?.property?.basicInformation?.name && (
                    <>
                      <span className="local-header__menu-top-right-location-icon  icon-location t-accent-color"></span>
                      <a
                        className="local-header__menu-top-right-location-link local-header__menu-top-links ml-2 pl-1 pl-md-0 pr-md-4 pr-xl-0 mr-md-1 mr-xl-0 ml-md-3 t-font-s nonChineseContent"
                        tabIndex={0}
                        target={
                          preProcessorResponse?.sameTab || preProcessorResponse?.cna
                            ? '_self'
                            : pageProps?.openMapInNewTab === 'true'
                            ? '_blank'
                            : '_self'
                        }
                        href={`${mapURL}?api=1&query=${data?.property?.basicInformation?.name?.replaceAll(' ', '+')}`}
                        rel="noreferrer"
                      >
                        <span className="view-map-desktop d-none d-xl-inline-block">{pageProps?.mapCtaLabel}</span>
                        <span className="view-map-mobile d-inline-block d-xl-none">{pageProps?.mobileMapCtaLabel}</span>
                      </a>
                      <a
                        className="local-header__menu-top-right-location-link local-header__menu-top-links ml-2 pl-1 pl-md-0 pr-md-4 pr-xl-0 mr-md-1 mr-xl-0 ml-md-3 t-font-s chineseContent loading-map"
                        tabIndex={0}
                        target={
                          preProcessorResponse?.sameTab || preProcessorResponse?.cna
                            ? '_self'
                            : pageProps?.openMapInNewTab === 'true'
                            ? '_blank'
                            : '_self'
                        }
                        href={`${baiduMapURL}?latlng=${data?.property?.basicInformation?.latitude},${data?.property?.basicInformation?.longitude}`}
                        rel="noreferrer"
                      >
                        <span className="view-map-desktop d-none d-xl-inline-block">{pageProps?.mapCtaLabel}</span>
                        <span className="view-map-mobile d-inline-block d-xl-none">{pageProps?.mobileMapCtaLabel}</span>
                      </a>
                    </>
                  )}
                </div>
                <div className="local-header__menu-top-right-phone ml-xl-5 pl-xl-3 d-flex align-items-center">
                  {data && data?.property?.contactInformation?.contactNumbers?.[0]?.phoneNumber?.display && (
                    <>
                      <span className="local-header__menu-top-right-phone-icon icon-phone t-accent-color ml-1 ml-3 ml-md-4 pl-0 pl-xl-0 pl-md-1 ml-xl-0"></span>
                      <a
                        className="local-header__menu-top-right-phone-link local-header__menu-top-links ml-2 mr-3 mr-md-0 pl-1 pl-md-0 ml-md-3 t-font-s"
                        tabIndex={0}
                        href={`tel:${data?.property?.contactInformation?.contactNumbers?.[0].phoneNumber?.display}`}
                      >
                        {convertPhoneNumberToDE(
                          data?.property?.contactInformation?.contactNumbers?.[0]?.phoneNumber?.display
                        )}
                      </a>
                    </>
                  )}
                </div>
                <div className="local-header__menu-top-reserve p-3 p-xl-0 p-md-3 p-xl-0 ml-xl-3">
                  <Link
                    linkHref={pageProps?.buttonLink ?? ''}
                    linkClassName="local-header__menu-top-reserve_button m-button-s m-button-secondary ml-0 ml-md-3 ml-xl-5 d-flex align-items-center justify-content-center col-auto col-xl-auto py-0 my-0 my-md-3 my-xl-0 mr-0 mr-md-3 mr-xl-0"
                    target={
                      preProcessorResponse?.sameTab || preProcessorResponse?.cna
                        ? '_self'
                        : pageProps?.openButtonLinkInNewTab === 'true'
                        ? '_blank'
                        : '_self'
                    }
                    rel="noreferrer"
                  >
                    {pageProps?.buttonLabel}
                  </Link>
                </div>
              </div>
            </div>

            {!hideNavigation && pageProps?.navigationAttributes?.length > 0 && (
              <div
                className="local-header__menu-bottom d-flex flex-wrap justify-content-between col-12 p-0"
                ref={singleRefAnchorList}
                data-testid="local-header__menu-bottom_test"
              >
                <div className="local-header__menu-bottom-container flex-wrap col-12 p-0">
                  <ul
                    className={`local-header__menu-bottom-list d-flex my-2 pl-3 pl-md-0 justify-content-md-center ${
                      l2NavLinks.length <= 2 ? 'justify-content-center' : ''
                    }`}
                  >
                    {l2NavLinks}
                  </ul>
                </div>
                <div
                  className="accu-weather-fragment container rendered"
                  data-id="headerAccuweather"
                  id="headerAccuweather"
                  data-compid="headerAccuweather"
                >
                  {showAccuWeatherContainer}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </StyledLocalHeader>
  );
};

export const LocalHeaderConfig = {
  emptyLabel: 'LocalHeader',
  isEmpty: (_props: MappedComponentProperties) => false,
  resourceType: `${NEXT_PUBLIC_AEM_SITE}/components/content/localheader`,
} as Config<MappedComponentProperties>;

export const LocalHeaderEditable = (props: LocalHeaderProps) => (
  <EditableComponent config={LocalHeaderConfig} {...props}>
    <LocalHeader {...props} />
  </EditableComponent>
);
