// Styles for InternetBar go here.
import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import { toRem, baseVariables, contextualVariable } from '@marriott/mi-ui-library';

export const StyledInternetBar = styled.div`
  ${rtl`
    .internet-bar-container {
      font-family: ${contextualVariable.brandedFontFamily};
      position: absolute;
      width: fit-content;
      height: fit-content;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      inset: 0;
      margin: auto;
      border-radius: ${toRem(15)};


      a[disabled] {
        pointer-events: none;
        cursor: not-allowed;
      }

      button.disable-join-now {
        pointer-events: none;
        cursor: not-allowed;
      }
        
      button.enable-join-now {
        pointer-events: auto;
        cursor: pointer;
      }

      .internet-bar-content {
        border-bottom: ${toRem(10)} solid ${contextualVariable.accentColor};
        border-radius: ${toRem(15)};

        .loyalty-container {
          &-card1 {
            @media ${baseVariables.mediaQuery.md} {
              padding-right: ${toRem(12)} !important;
            }
            @media ${baseVariables.mediaQuery.xl} {
              padding-right: ${toRem(8)} !important;
            }
          }
          &-card2 {
            padding-left: ${toRem(8)} !important;
            @media ${baseVariables.mediaQuery.md} {
              padding-left: ${toRem(12)} !important;
            }
            @media ${baseVariables.mediaQuery.xl} {
              padding-left: ${toRem(8)} !important;
            }
          }
        }
        &__icon {
          border-radius: 50%;
          padding: ${toRem(8)} ${toRem(10)} ${toRem(6)};
          border: ${toRem(1)} solid ${contextualVariable.accentColor};
          font-size: ${baseVariables.font['fontXxl']};
        }

        &__separator {
          width: ${toRem(100)};
          border-bottom: ${toRem(2)} solid ${contextualVariable.accentColor};
          margin: auto;
        }
      }
    }

    .internet-bar-container {
      .internet-bar-content-in_stay {
        padding: 0 ${toRem(24)};
        margin-bottom: ${toRem(-3)};
        min-width: ${toRem(280)};
        
        @media ${baseVariables.mediaQuery.xl} {
          width: ${toRem(555)};
        }
        
        &-signed-in{
          @media ${baseVariables.mediaQuery.xl} {
            padding 0 ${toRem(110)} !important;
          }
        }

        &__description-container{
          p a {
            text-decoration: underline;
          }
        }

        &-signed-out{
          @media ${baseVariables.mediaQuery.xl} {
            padding 0 ${toRem(70)} !important;
          }
        }
        
        &__button-container{
          @media ${baseVariables.mediaQuery.xl} {
            padding 0 ${toRem(80)} !important;
          }
        }
      }

      .internet-bar-content-upgrade, .internet-bar-content-upgraded {
        padding: 0 ${toRem(24)};
        min-width:  ${toRem(280)};
        
        @media ${baseVariables.mediaQuery.xl} {
          width: ${toRem(555)};
          padding: 0 ${toRem(49)} !important;
        }
        &__description-container {
          p a {
            text-decoration: underline;
          }
        }
        &__button-container{
          @media ${baseVariables.mediaQuery.xl} {
            padding 0 ${toRem(102)} !important;
          }
        }
        &__footer-container {
          p a {
            text-decoration: underline;
          }
        }
      }
    }

    .internet-bar-container {
      .internet-bar-content-connected {
        padding: 0 ${toRem(24)};
        min-width: ${toRem(280)};
        position: relative;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        margin-bottom: ${toRem(-3)};

        @media ${baseVariables.mediaQuery.xl} {
          width: ${toRem(555)};
          padding 0 ${toRem(49)} !important;
        }
        &__description-container{
          p a {
            text-decoration: underline;
          }
        }
        &__button-container {
          @media ${baseVariables.mediaQuery.xl} {
          padding: 0 ${toRem(102)};
          }
        }

        &__button-content {
          > a:only-child {
            margin-bottom: ${toRem(48)};
          }

          > :first-child:not(:only-child) {
            margin-bottom: ${toRem(16)};

            @media ${baseVariables.mediaQuery.xl} {
              margin-bottom: ${toRem(8)};
            }
          }
        }
      }
    }

    .internet-bar-container {
      .internet-bar-content-preconnect {
        padding: 0 ${toRem(24)};
        min-width: ${toRem(280)};

        @media ${baseVariables.mediaQuery.xl} {
          padding: 0 ${toRem(42)};
          width: ${toRem(555)};
        }
        &__description-container {
          p a {
            text-decoration: underline;
          }
        }
        &__footer-container { 
          p a {
            text-decoration: underline;
            }
          }
        }
      }
    }

    .internet-bar-container {
      .internet-bar-content-preconnect-loyalty {
        padding: 0 ${toRem(16)};
        min-width: ${toRem(280)};

        @media ${baseVariables.mediaQuery.md} {
          padding: 0 ${toRem(32)};
          width: ${toRem(584)};
        }

        @media ${baseVariables.mediaQuery.xl} {
          padding: 0 ${toRem(32)};
          width: ${toRem(650)};
        }

        .internet-bar-content__footer-container {
          &__tnc-text a {
            text-decoration: underline;
          }
        }
        
        .loyalty-buttons {         
          &:lang(EN){
            width: ${toRem(160)};
          }
        }
      }
    }

    .flex-1 {
      flex: 1;
    }
  `}
`;
