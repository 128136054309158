import React, { FC } from 'react';
import { ProgressTrackerProps } from './ProgressTracker.types';
import { StyledProgressTracker } from './ProgressTracker.styles';

export const ProgressTracker: FC<ProgressTrackerProps> = ({ steps, currentStep }) => {
  const getStatus = (index: number, currentStep: number) => {
    if (index + 1 < currentStep) {
      return 'completed';
    } else if (index + 1 === currentStep) {
      return 'active';
    }
    return '';
  };

  return (
    <StyledProgressTracker data-component-name="m-groups-ProgressTracker" data-testid="groups-ProgressTracker">
      <ul className="progress-tracker-wrapper">
        {steps.map((step, index) => {
          return (
            <li key={index} className={`progress-tracker-item ${getStatus(index, currentStep)}`}>
              <span className="progress-tracker-counter">{index + 1}</span>
              <span className="progress-tracker-label">{step}</span>
            </li>
          );
        })}
      </ul>
    </StyledProgressTracker>
  );
};
