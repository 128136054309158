import { FC, useContext, useEffect, useState, useRef } from 'react';
import clsx from 'clsx';
import { Button } from '@marriott/mi-ui-library';
import { PageParamsContext } from '../../../../modules/context/PageContext';
import { ButtonTypeVariation } from '../../../../modules/utils/enums/enums';
import { handleDurationDropdownKeyDownEvent, submitDynamicForm } from '../../utils/handler';
import { InternetBarPricingModalProps, IDuration } from './InternetBarModal.types';
import { formatCurrency } from '../../../../modules/utils/common';
import { DEFAULT_LOCALE } from '../../utils/constant';

export const InternetBarUpgradeModal: FC<InternetBarPricingModalProps> = (props: InternetBarPricingModalProps) => {
  const { preProcessorResponse } = useContext(PageParamsContext);
  const { experienceSegment = '', state = '', locale } = preProcessorResponse || {};
  const { internetPricingModel, dropdownRef, setShowDropdown, showDropdown, setShowModal, showModal } = props;
  const internetPricingInfoUpgrade = { ...internetPricingModel?.internetPricingInfo };
  const upgradeModalRef = useRef(null);
  const dropdownListRef = useRef(null);

  const {
    APS,
    DD = '',
    DOMAIN = '',
    DURATION = '',
    MARSHA = '',
    MS,
    Z = '',
    zoneMapping = '',
    postEndpoint = '',
  } = props?.internetBarDataValues || {};

  const upgradeConnectionButtonRef = useRef(null);
  const upgradeDurationDropdownRef = useRef(null);

  const MAX_DURATION = 31;

  const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false);

  const durations: IDuration[] = [];

  for (let i = 0; i < MAX_DURATION; i++) {
    if (i) {
      durations.push({ key: `${i + 1}-days`, value: `${i + 1} ${props?.internetPricingModel?.justDaysLabel}` });
    } else {
      durations.push({ key: `${i + 1}-day`, value: `${i + 1} ${props?.internetPricingModel?.justDayLabel}` });
    }
  }

  const [duration, setDuration] = useState(DD && parseInt(DD) ? durations[parseInt(DD) - 1].value : durations[0].value);

  if (
    experienceSegment?.toLowerCase()?.includes('basic') ||
    experienceSegment?.toLowerCase()?.includes('member') ||
    (state.toLowerCase() === 'upgrade' &&
      (experienceSegment?.toLowerCase()?.includes('basic') ||
        experienceSegment?.toLowerCase()?.includes('member') ||
        experienceSegment?.toLowerCase()?.includes('unknown')))
  ) {
    if (
      parseFloat(internetPricingInfoUpgrade?.tierTwoFee) &&
      parseFloat(internetPricingInfoUpgrade?.tierOneFee) &&
      parseFloat(internetPricingInfoUpgrade?.tierTwoFee) > parseFloat(internetPricingInfoUpgrade?.tierOneFee) &&
      Z === 'GUEST'
    ) {
      internetPricingInfoUpgrade.tierTwoFee = (
        parseFloat(internetPricingInfoUpgrade?.tierTwoFee) - parseFloat(internetPricingInfoUpgrade?.tierOneFee)
      ).toFixed(2);
    } else if (
      parseFloat(internetPricingInfoUpgrade?.tierTwoFee) < parseFloat(internetPricingInfoUpgrade?.tierOneFee)
    ) {
      internetPricingInfoUpgrade.tierTwoFee = '0';
    }
  } else if (experienceSegment?.toLowerCase()?.includes('elite')) {
    internetPricingInfoUpgrade.tierTwoFee = '0';
  }

  const isFree =
    internetPricingInfoUpgrade.tierTwoFee === '' ||
    internetPricingInfoUpgrade.tierTwoFee === undefined ||
    parseFloat(internetPricingInfoUpgrade.tierTwoFee).toFixed(0) === '0';

  /**
   * @description upgrade modal and it's post data OSD-1280, 1299
   * @param evt any
   */
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  const upgradeConnection = () => {
    const upgradeDurationValue = internetPricingInfoUpgrade?.tierTwoFee ? duration ?? DURATION : '7';
    const currency = internetPricingInfoUpgrade?.currencyTwo ?? props?.internetPricingModel?.currency;
    const price = internetPricingInfoUpgrade?.tierTwoFee;
    setIsButtonLoading(true);

    if (postEndpoint && upgradeDurationValue) {
      try {
        const obj = {
          MARSHA: MARSHA ? MARSHA : undefined,
          ACTION: 'UPGRADED',
          DOMAIN: DOMAIN ? DOMAIN : undefined,
          MS: MS || MS === '' ? MS : undefined,
          APS: APS || APS === '' ? APS : undefined,
          DURATION: isFree
            ? '7'
            : upgradeDurationValue
            ? upgradeDurationValue.includes(props?.internetPricingModel?.justDaysLabel)
              ? upgradeDurationValue?.replace(props?.internetPricingModel?.justDaysLabel, '')?.trim()
              : upgradeDurationValue?.replace(props?.internetPricingModel?.justDayLabel, '')?.trim()
            : undefined,
          CURRENCY: price && price !== '0' ? (currency ? currency : undefined) : undefined,
          PRICE: price && price !== '0' ? price : undefined,
          TIERS: zoneMapping[Z ?? ''] ?? undefined,
        };
        const body = JSON.stringify(obj);
        submitDynamicForm(body, postEndpoint);
      } catch (error) {
        // ignore
      }
    }
  };

  useEffect(() => {
    if (upgradeModalRef && upgradeModalRef?.current) {
      if (!(upgradeModalRef?.current as HTMLElement)?.ariaLabel?.trim()) {
        (upgradeModalRef.current as HTMLElement).setAttribute(
          'aria-label',
          `${props?.internetPricingModel?.intUpgNoThanksLink ?? ''}`
        );
      }
    }
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (upgradeDurationDropdownRef && upgradeDurationDropdownRef?.current) {
        (upgradeDurationDropdownRef?.current as HTMLInputElement)?.focus();
      } else if (upgradeConnectionButtonRef && upgradeConnectionButtonRef?.current) {
        (upgradeConnectionButtonRef?.current as HTMLInputElement)?.focus();
      }
    }, 20);
    return () => clearTimeout(timeout);
  }, []);

  return showModal ? (
    <div className="internet-bar-component p-4 m-2">
      <div className="internet-bar-component__body d-flex flex-column">
        <div className={`d-flex flex-column internet-bar-component__header internet-bar-component`}>
          <span className="internet-bar-component__title t-label-s w-100 py-2 text-center">
            {props?.internetPricingModel?.intUpgTierTitle}
          </span>
          <div className="d-flex flex-column text-center align-items-center">
            {isFree ? (
              <div className="d-flex flex-column align-items-center">
                <span className="t-font-m modal-label pt-2 my-1 text-center">
                  {props?.internetPricingModel?.intUpgFreeLabel}
                </span>
                <span className="internet-bar-component-seperator-line t-accent-color mt-2"></span>
              </div>
            ) : (
              <div className="d-flex flex-column align-items-center">
                <span className="t-font-m modal-label pt-2 my-1">
                  {formatCurrency(
                    parseFloat(internetPricingInfoUpgrade?.tierTwoFee),
                    locale ?? DEFAULT_LOCALE,
                    internetPricingInfoUpgrade?.currencyTwo ?? internetPricingModel?.currency
                  )}
                </span>
                <span className="t-font-xs pt-2">
                  {internetPricingInfoUpgrade?.currencyTwo ?? internetPricingModel?.currency}
                  {props?.internetPricingModel?.dayLabel}
                </span>
                <span className="internet-bar-component-seperator-line t-accent-color mt-2"></span>
              </div>
            )}
            <div className="d-flex flex-column">
              {DD?.toUpperCase() !== 'R' && DD?.toUpperCase() !== 'D' && (
                <span className="t-overline-normal pt-2 pb-3 px-3 internet-bar-component-desc modal-price-desc general-description">
                  {props?.internetPricingModel?.intUpgDescription}
                </span>
              )}
              {DD?.toUpperCase() === 'R' && (
                <span className="t-overline-normal pt-2 pb-3 px-3 mx-2 mx-md-0 internet-bar-component-desc modal-price-desc resort-description">
                  {props?.internetPricingModel?.intUpgResortDescription}
                </span>
              )}
              {DD?.toUpperCase() === 'D' && (
                <span className="t-overline-normal pt-2 pb-3 px-3 mx-2 mx-md-0 internet-bar-component-desc modal-price-desc desctination-description">
                  {props?.internetPricingModel?.intUpgDestinationDescription}
                </span>
              )}
            </div>
          </div>
        </div>
        {!isFree && (
          <div className="m-form-group py-5 mt-3 mb-0 internet-bar-component-duration">
            <div className="t-font-s mb-2">{props?.internetPricingModel?.intUpgDurationLabel}</div>
            <div ref={dropdownRef}>
              <Button
                isLink={false}
                type={ButtonTypeVariation?.Button}
                className={clsx(
                  `d-flex justify-content-between pt-0 pb-2 px-0 internetbarmodal-${props?.modalType} internetbarmodal-${props?.modalType}__dropdownbtn`
                )}
                ref={upgradeDurationDropdownRef}
                testId="upgrade-dropdown"
                callback={event => {
                  event?.stopPropagation();
                  setIsButtonLoading(false);
                  showDropdown
                    ? setShowDropdown !== undefined && setShowDropdown(false)
                    : setShowDropdown !== undefined && setShowDropdown(true);

                  setTimeout(() => {
                    const dropdownList = dropdownListRef?.current;
                    if (dropdownList) {
                      const list = (dropdownList as HTMLElement)?.children;
                      const dIndex = durations.findIndex(d => d.value === duration);
                      const dValue = list[dIndex];
                      (dValue as HTMLElement)?.focus();
                    }
                  }, 20);
                }}
                children={
                  <>
                    <span className="t-font-m pb-1">{duration}</span>
                    <span className={clsx(showDropdown ? 'icon-arrow-down' : 'icon-arrow-up')}></span>
                  </>
                }
              />
              {showDropdown && (
                <ul
                  className={clsx(
                    `mt-3 p-0 mb-0 internetbarmodal-${props?.modalType} internetbarmodal-${props?.modalType}__dropdownlist`
                  )}
                  ref={dropdownListRef}
                >
                  {durations.map(item => {
                    return (
                      <li
                        // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
                        role={'button'}
                        key={item.key}
                        id={item.value}
                        data-testid={item.value}
                        className={`pt-3 pb-3 d-flex justify-content-between align-items-center internetbarmodal-${props?.modalType} internetbarmodal-${props?.modalType}__dropdownitems`}
                        tabIndex={0}
                        onClick={event => {
                          event?.stopPropagation();
                          setDuration(item.value);
                          setShowDropdown !== undefined && setShowDropdown(false);
                        }}
                        onKeyDown={event => {
                          if (event?.code?.toLowerCase() === 'enter' || event?.code?.toLowerCase() === 'space') {
                            event?.stopPropagation();
                            setDuration(item.value);
                            setShowDropdown !== undefined && setShowDropdown(false);
                            setTimeout(() => {
                              if (upgradeDurationDropdownRef && upgradeDurationDropdownRef?.current) {
                                (upgradeDurationDropdownRef?.current as HTMLButtonElement)?.focus();
                              }
                            }, 20);
                          } else {
                            handleDurationDropdownKeyDownEvent(
                              event as unknown as KeyboardEvent,
                              dropdownListRef,
                              durations,
                              item
                            );
                          }
                        }}
                      >
                        <span className="value" data-code={item.value}>
                          {item.value}
                        </span>
                        <span className="icon-forward-arrow arrow ml-2 pr-3"></span>
                      </li>
                    );
                  })}
                </ul>
              )}
            </div>
          </div>
        )}
        <button
          className={`m-button m-button-m m-button-primary t-font-m internet-bar-component-button mt-4 ${
            isButtonLoading ? 'disabled' : ''
          }`}
          data-sp-value="PLAN2"
          onClick={upgradeConnection}
          onFocus={() => {
            setShowDropdown !== undefined && setShowDropdown(false);
          }}
          disabled={isButtonLoading}
          ref={upgradeConnectionButtonRef}
          data-testid="upgrade-btn"
        >
          {isButtonLoading ? (
            <div className="m-spinner-s" data-testid="activate-spinner"></div>
          ) : (
            props?.internetPricingModel?.intUpgConnectionLabel
          )}
        </button>

        <Button
          isLink={true}
          callback={(e: MouseEvent | unknown) => {
            (e as MouseEvent).preventDefault();
            setShowModal !== undefined && setShowModal(false);
          }}
          ref={upgradeModalRef}
          className={`t-font-xs mt-3 text-center internet-bar-component-dialog-return_connection internetbarmodal__link`}
          children={props?.internetPricingModel?.intUpgNoThanksLink}
          href=""
        />
      </div>
    </div>
  ) : (
    <div className="d-none"></div>
  );
};
