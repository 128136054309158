import styled from 'styled-components';
import { toRem, baseVariables } from '@marriott/mi-ui-library';
import rtl from 'styled-components-rtl';
import { constants } from '../../../modules/utils/constants/constants';

export const StyledSearchAllFilters = styled.div`
  .filters-button-container {
    column-gap: ${toRem(8)};

    .filter-count {
      background-color: ${baseVariables.color.merch30};
      color: ${baseVariables.color.merch50};
      border-radius: ${toRem(20)};
      padding: ${toRem(2)} ${toRem(6)};

      ${rtl`
        &:is(${constants.RTL_LANG_LIST}) {  
          padding: 0 ${toRem(7)};
        }
      `}
    }
  }

  .filter-option-button.active {
    background-color: ${baseVariables.color['close-silver']};
  }

  .filter-option-button {
    font-weight: ${baseVariables.font['fontWeightRegular']};
    padding: ${toRem(8)} ${toRem(12)};
    border-radius: ${toRem(8)};
    background-color: ${baseVariables.color.base20};
    border: 1px solid ${baseVariables.color.neutral30};

    &:hover {
      background-color: ${baseVariables.color.base20};
      border: 1px solid ${baseVariables.color.neutral30};
    }

    &__active {
      border: 1px solid ${baseVariables.color.neutral40};
      background-color: ${baseVariables.color.neutral20};
      border-radius: ${toRem(8)};

      &:hover {
        border: 1px solid ${baseVariables.color.neutral40};
        background-color: ${baseVariables.color.neutral20};
      }
    }
  }
  .filter-option-button:focus {
    outline: 0px solid -webkit-focus-ring-color;
  }

  .icon-dining-filter {
    margin: 0 ${toRem(5)} 0 0;
    top: -4px;
  }

  .filter-separator {
    margin: 40px 0 0 0;
    padding: 0;
    border-top: ${toRem(1)} solid ${baseVariables.color.neutral30};
    border-bottom: none;
    border-left: none;
    border-right: none;
  }

  .all-filters.custom-scrollbar {
    margin-top: 0 !important;
    padding: 0 ${toRem(16)};
    margin-bottom: ${toRem(24)} !important;

    @media ${baseVariables.mediaQuery.md},
      @media ${baseVariables.mediaQuery.lg},
      @media ${baseVariables.mediaQuery.xl} {
      padding: 0 ${toRem(20)};
    }
  }

  .modal-main-container .custom-scrollbar {
    position: relative;
    max-width: 46.5rem;
    margin: 3.5rem auto;
  }

  .modal-header {
    letter-spacing: 0;

    .popup-close {
      width: ${toRem(32)};
      height: ${toRem(32)};
    }

    @media only ${baseVariables.mediaQuery.mobile && baseVariables.mediaQuery.allTablets} {
      padding: ${toRem(48)} ${toRem(24)} ${toRem(32)} ${toRem(24)};
    }
  }

  @media only ${baseVariables.mediaQuery.mobile && baseVariables.mediaQuery.allTablets} {
    .filter-heading {
      font-size: ${toRem(16)};
    }
    .modal-main-container .custom-scrollbar {
      margin: ${toRem(47)} auto 0;
    }
  }

  .modal-footer {
    border-radius: 0;
    padding: ${toRem(12)} ${toRem(16)};

    @media ${baseVariables.mediaQuery.md},
      @media ${baseVariables.mediaQuery.lg},
      @media ${baseVariables.mediaQuery.xl} {
      border-radius: 0 0 ${toRem(14)} ${toRem(14)};
      padding: ${toRem(20)} ${toRem(28)};
    }

    .apply-button {
      width: auto;
    }
  }

  .fixed-size-modal {
    min-height: 36vh;

    @media ${baseVariables.mediaQuery.md},
      @media ${baseVariables.mediaQuery.lg},
      @media ${baseVariables.mediaQuery.xl} {
      min-height: 52vh;
    }
  }

  .filter-section {
    display: grid;
    grid-template-columns: max-content auto;
  }

  .scrollable-section {
    overflow-x: scroll;
    cursor: grab;
  }

  .scrollable-section.active {
    cursor: grabbing;
  }

  .scrollable-section::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .scrollable-section {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .loader-container {
    background: rgba(255, 255, 255, 0.7);
    z-index: 9998;
    width: 100%;
    position: absolute !important;
    top: 0;
    height: auto;
    margin: 0 auto;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: ${toRem(14)};
  }

  ${rtl`
    &:is(${constants.RTL_LANG_LIST}) {
      .icon-dining-filter {
        margin: 0 0 0 ${toRem(5)};

        &::before { 
          padding-right: 0 !important;
          padding-left: ${toRem(10)};
        }
      }

      .checkbox-controls-list__checkbox, .radio-controls-list__radio {
        input[type=checkbox] + label, input[type=radio] + label {
          padding-right: ${toRem(30)};
          padding-left: 0;

          &::before {
            right: 0;
            left: unset;
          }
        }
      }

      .toggle-switch-wrapper {
        .toggle-switch {
          margin-left: ${toRem(8)};
          margin-right: 0 !important;
        }
        .t-font-s {
          padding-right: ${toRem(4)};
          padding-left: 0;
        }
      }

      .brand-filter__category_label_title {
        padding-left: ${toRem(6)};
        padding-right: 0 !important;
        border-left: 0.094rem solid rgb(112, 112, 112);
        border-right: none !important;
      }

      .modal-footer {
        .clear {
          margin-left: ${toRem(27)};
          margin-right: 0;
        }
      }
    }
  `}
`;
