import styled from 'styled-components';
import { baseVariables, toRem } from '@marriott/mi-ui-library';

const { color, border } = baseVariables;

export const StyledEventCard = styled.div`
  border: ${toRem(1)} ${border.borderStyleDefault} ${color.neutral20};
  background-color: ${color.base20};
  box-shadow: 0 0 ${toRem(8)} 0 rgba(0, 0, 0, 0.07);
  margin-bottom: ${toRem(16)};

  .disabled {
    opacity: 0.4;
  }

  .image-container {
    .event-image {
      width: 100%;
      height: ${toRem(186)};
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .no-image {
      gap: ${toRem(30)};
      height: ${toRem(186)};
      background-color: ${color.neutral10};
      .icon-photo-gallery {
        font-size: ${toRem(64)};
      }
    }
  }

  .event-details {
    .event-title {
      word-wrap: break-word;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      min-height: ${toRem(66)};
    }

    .event-date {
      min-height: ${toRem(46)};
      .start-date {
        order: 1;
      }
      .separator {
        order: 2;
      }
      .end-date {
        order: 3;
      }
    }

    .event-ctas {
      word-break: break-all;
      a {
        color: ${color.alert40};
        .icon-arrow-right {
          font-size: ${toRem(16)};
        }
      }
    }
  }
`;
