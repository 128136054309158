// Styles for LocalHeader go here.
import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import { toRem, baseVariables, contextualVariable } from '@marriott/mi-ui-library';

export const StyledHeroBanner = styled.div`
  ${rtl`
  .hero-component {
    &__internetbar-container{
      position: absolute;
      width: ${toRem(555)};
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      inset: 0;
      margin: auto;
      border-bottom: ${toRem(10)} solid ${contextualVariable.accentColor};
      border-radius: ${toRem(15)};
    }
    &-container {
      position: relative;
      transition: height 0.3s ease-in;
      height: ${toRem(450)};
      
      @media ${baseVariables.mediaQuery.md} {      
        height: ${toRem(450)};
      }

      @media ${baseVariables.mediaQuery.xl} {
        height: ${toRem(480)};
      }
    }
    img {
      height: -webkit-fill-available;
      object-fit: cover;

      @media ${baseVariables.mediaQuery.xl} {
        height: ${toRem(480)};
      }
    }
  }
  `}
`;
