import axios, { AxiosResponse } from 'axios';
import Cookies from 'js-cookie';
import { INLINE_ENROLLMENT_DUPLICATE_EMAIL, INLINE_ENROLLMENT_URL, HTTP_SUCCESS_CODE } from '../constants';

export type LoginPayload = {
  userID: string;
  password: string;
  signInUrl: string;
  returnUrl: string;
  errorUrl: string;
};

export type LogoutPayload = {
  logoutUrl: string;
  returnUrl: string;
};

type UserDetails = {
  firstName: string;
  lastName: string;
  emailAddress: string;
  pwrd: string;
  confirmPwrd: string;
  rememberMe: boolean;
  countryCode: string;
  postalCode: string;
};

type UserConsent = {
  code: 'CORE_REQUIRED_CONSENT' | 'PERSONALIZED_MARKETING_CONSENT';
  optIn: boolean;
};

type CommunicationPreference = {
  type: 'subscriptionsOffers' | 'subscriptions3rdParty';
  optIn: boolean;
};

export type EnrollUserPayload = {
  userDetails: UserDetails;
  userConsents: UserConsent[];
  communicationPreferences: CommunicationPreference[];
};

export const login = async (payload: LoginPayload): Promise<AxiosResponse> => {
  const searchParams = new URLSearchParams();
  Object.keys(payload).forEach((key: string) => {
    searchParams.append(key, payload[key as keyof LoginPayload] as string);
  });

  return axios.post(payload.signInUrl, searchParams, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
};

export const logout = async (payload: LogoutPayload): Promise<AxiosResponse> => {
  const url = `${payload.logoutUrl}?returnTo=${payload.returnUrl}`;
  return axios.get(url);
};

export const getRememberedUserDetails = async (url: string, sessionID: string, locale: string) => {
  const payload = {
    sessionToken: sessionID,
    context: {
      context: {
        localeKey: locale,
      },
    },
  };

  return axios.post(url, payload, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const clearRememberedUserDetails = async (url: string): Promise<AxiosResponse> => {
  return axios.get(url);
};

export const enrollUser = async (data: EnrollUserPayload) => {
  let rewardId = '';
  let isDuplicateEmailError = false;

  try {
    const response = await axios.post(INLINE_ENROLLMENT_URL, data, {
      headers: {
        Cookie: Cookies.get('sessionID')?.toString() ?? '',
        'Content-Type': 'application/json',
      },
    });

    if (response.status === HTTP_SUCCESS_CODE) {
      rewardId = response.data.customerDetails.rewardsId;
    }
  } catch (error: unknown) {
    const enrollError = error as { response?: { data?: { message: string } } };
    isDuplicateEmailError = enrollError?.response?.data?.message === INLINE_ENROLLMENT_DUPLICATE_EMAIL;
  }

  return { rewardId, isDuplicateEmailError };
};
