import { FC, useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { Lookup, LookupType, AddressType } from '@marriott/mi-groups-graphql';
import { RadiobuttonControlsList, baseVariables } from '@marriott/mi-ui-library';
import {
  ADDRESS_REGEX,
  USA,
  CANADA,
  LETTERS_REGEX,
  CITY_REGEX,
  GENERAL_ZIP_CODE_REGEX,
  USA_ZIP_CODE_REGEX,
  CANADA_ZIP_CODE_REGEX,
} from '../../constants';
import { useLookupsByType, useMediaQuery } from '../../hooks';
import { useLocaleStore } from '../../store';
import { TextFormField } from '../TextFormField';
import { Dropdown } from '../Dropdown';
import { DropdownOption } from '../Dropdown/Dropdown.types';
import { AddressInfoProps } from './AddressInfo.types';
import { StyledAddressInfo } from './AddressInfo.styles';

export const AddressInfo: FC<AddressInfoProps> = props => {
  const {
    labels,
    customerAddressType,
    customerCountry,
    customerState,
    control,
    setValue,
    errors,
    clearErrors,
    onApiError,
  } = props;
  const {
    title,
    personalLabel,
    businessLabel,
    companyName,
    addressLine1,
    addressLine2,
    country,
    city,
    state,
    zipcode,
  } = labels;

  const isTabletAndAbove = useMediaQuery(baseVariables.mediaQuery.md);

  const { locale } = useLocaleStore();

  const [showCompanyName, setShowCompanyName] = useState<boolean>(true);
  const [countryOptions, setCountryOptions] = useState<DropdownOption<Lookup>[]>([]);
  const [selectedCountry, setSelectedCountry] = useState(USA);
  const [stateOptions, setStateOptions] = useState<DropdownOption<Lookup>[]>([]);
  const [selectedState, setSelectedState] = useState(state.defaultValue);

  const {
    lookups: countries,
    loading: countriesLoading,
    error: countriesError,
  } = useLookupsByType(LookupType.COUNTRIES);
  const { lookups: states, loading: statesLoading, error: statesError } = useLookupsByType(LookupType.STATES);

  const sortOptions = (lookups: Lookup[]) => {
    return [...lookups]
      .sort((a, b) => a.description.localeCompare(b.description))
      .map(lookup => ({
        ...lookup,
        label: lookup.description,
        value: lookup.code,
      }));
  };

  useEffect(() => {
    customerAddressType && handleAddressTypeChange(customerAddressType as AddressType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerAddressType]);

  useEffect(() => {
    if (countries) {
      const options = sortOptions(countries);
      setCountryOptions(options);
    }
  }, [countries]);

  useEffect(() => {
    if (customerCountry) {
      setSelectedCountry(customerCountry);
    } else {
      const countryCode = locale?.split('-')[1];
      const defaultCountry = countries?.find((country: Lookup) => country.code === countryCode)?.code;
      defaultCountry && setSelectedCountry(defaultCountry);
    }
  }, [customerCountry, locale, countries]);

  useEffect(() => {
    if (states) {
      const options = sortOptions(states);
      setStateOptions(options);
    }
  }, [states]);

  useEffect(() => {
    if (customerCountry === USA && customerState && stateOptions.length) {
      const state = stateOptions.find(option => option.code === customerState);
      if (state) {
        setValue('addressInfo.state', JSON.stringify(state));
        setSelectedState(state?.code as string);
      }
    } else {
      setValue('addressInfo.state', customerState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerState, customerCountry, stateOptions]);

  useEffect(() => {
    if (onApiError && (countriesError || statesError)) {
      onApiError();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countriesError, statesError]);

  const handleAddressTypeChange = (selectedAddressType: AddressType) => {
    setValue('addressInfo.addressType', selectedAddressType);
    if (selectedAddressType === AddressType.BUSINESS) {
      setShowCompanyName(true);
    } else {
      setShowCompanyName(false);
      setValue('addressInfo.companyName', '');
      clearErrors('addressInfo.companyName');
    }
  };

  const addressOptions = [
    { code: AddressType.BUSINESS, label: businessLabel },
    { code: AddressType.PERSONAL, label: personalLabel },
  ];

  const isCountryUsa = selectedCountry === USA;
  const isCountryCanada = selectedCountry === CANADA;
  const isCountryUsaOrCanada = isCountryUsa || isCountryCanada;

  const addressTypeOptions = (
    <Controller
      name={'addressInfo.addressType'}
      control={control}
      render={({ field }) => (
        <RadiobuttonControlsList
          key="addressType"
          name="addressType"
          controls={addressOptions}
          defaultSelected={addressOptions.filter(item => item.code === field.value)}
          colLength={isTabletAndAbove ? 4 : 6}
          tabIndexForInput={-1}
          onChange={selectedValue => handleAddressTypeChange(selectedValue[0].code as AddressType)}
        />
      )}
    />
  );

  const companyNameField = (
    <Controller
      name="addressInfo.companyName"
      control={control}
      rules={{
        required: showCompanyName && companyName.requiredError,
      }}
      render={({ field }) => (
        <TextFormField
          {...field}
          type="text"
          label={companyName.label}
          ariaLabel={companyName.ariaLabel}
          className="m-input-field"
          maxLength={companyName.maxLength}
          showErrorMessage={!!errors?.addressInfo?.companyName?.message}
          errorMessage={errors?.addressInfo?.companyName?.message}
          disabled={!showCompanyName}
          testId="company-name"
          onChange={event => {
            field.onChange(event);
            clearErrors(field.name);
          }}
        />
      )}
    />
  );

  const addressLine1Field = (
    <Controller
      name="addressInfo.addressLine1"
      control={control}
      rules={{
        required: addressLine1.requiredError,
      }}
      render={({ field }) => (
        <TextFormField
          {...field}
          type="text"
          label={addressLine1.label}
          ariaLabel={addressLine1.ariaLabel}
          className="m-input-field"
          maxLength={addressLine1.maxLength}
          showErrorMessage={!!errors?.addressInfo?.addressLine1?.message}
          errorMessage={errors?.addressInfo?.addressLine1?.message}
          testId="address-line1"
          onChange={event => {
            field.onChange(event);
            clearErrors(field.name);
          }}
        />
      )}
    />
  );

  const addressLine2Field = (
    <Controller
      name="addressInfo.addressLine2"
      control={control}
      render={({ field }) => (
        <TextFormField
          {...field}
          type="text"
          label={addressLine2.label}
          ariaLabel={addressLine2.ariaLabel}
          className="m-input-field"
          maxLength={addressLine2.maxLength}
          showErrorMessage={!!errors?.addressInfo?.addressLine2?.message}
          errorMessage={errors?.addressInfo?.addressLine2?.message}
          testId="address-line2"
          onChange={event => {
            field.onChange(event);
            clearErrors(field.name);
          }}
        />
      )}
    />
  );

  const countryField = (
    <Controller
      name="addressInfo.country"
      control={control}
      render={({ field }) => {
        return (
          <Dropdown
            name="country"
            label={country.label}
            ariaLabel={country.ariaLabel}
            expandIconAriaLabel={labels.expandIconAriaLabel}
            collapseIconAriaLabel={labels.collapseIconAriaLabel}
            options={countryOptions}
            selectedValue={selectedCountry}
            disabled={countriesLoading || !!countriesError}
            onChange={event => {
              field.onChange(event);
              if (event.value !== selectedCountry) {
                setSelectedCountry(event.value);
                setValue('addressInfo.state', '');
              }
            }}
          />
        );
      }}
    />
  );

  const cityField = (
    <Controller
      name="addressInfo.city"
      control={control}
      rules={{
        required: city.requiredError,
        pattern: city.formatError
          ? {
              value: CITY_REGEX,
              message: `${city.formatError}`,
            }
          : undefined,
      }}
      render={({ field }) => (
        <TextFormField
          {...field}
          type="text"
          label={city.label}
          ariaLabel={city.ariaLabel}
          className="m-input-field"
          maxLength={city.maxLength}
          showErrorMessage={!!errors?.addressInfo?.city?.message}
          errorMessage={errors?.addressInfo?.city?.message}
          testId="city"
          onChange={event => {
            field.onChange(event);
            clearErrors(field.name);
          }}
        />
      )}
    />
  );

  const stateField = (
    <Controller
      name="addressInfo.state"
      control={control}
      rules={{
        required: isCountryUsaOrCanada && state.requiredError,
        pattern:
          !isCountryUsaOrCanada && state.formatError
            ? {
                value: LETTERS_REGEX,
                message: `${state.formatError}`,
              }
            : undefined,
      }}
      render={({ field }) =>
        isCountryUsa ? (
          <Dropdown
            name="state"
            label={state.label}
            ariaLabel={state.ariaLabel}
            expandIconAriaLabel={labels.expandIconAriaLabel}
            collapseIconAriaLabel={labels.collapseIconAriaLabel}
            placeholder={state.defaultValue}
            options={stateOptions}
            selectedValue={selectedState}
            disabled={statesLoading || !!statesError}
            isError={!!errors?.addressInfo?.state?.message}
            errorMessage={errors?.addressInfo?.state?.message}
            onChange={event => {
              field.onChange(JSON.stringify(event));
              clearErrors(field.name);
            }}
          />
        ) : (
          <TextFormField
            {...field}
            type="text"
            label={state.label}
            ariaLabel={state.ariaLabel}
            className="m-input-field"
            maxLength={state.maxLength}
            showHelperText={!!field.value}
            helperText={state.helperText}
            showErrorMessage={!!errors?.addressInfo?.state?.message}
            errorMessage={errors?.addressInfo?.state?.message}
            testId="state"
            onChange={event => {
              field.onChange(event);
              clearErrors(field.name);
            }}
          />
        )
      }
    />
  );

  let zipCodeRegex;

  if (isCountryUsa) {
    zipCodeRegex = USA_ZIP_CODE_REGEX;
  } else if (isCountryCanada) {
    zipCodeRegex = CANADA_ZIP_CODE_REGEX;
  } else {
    zipCodeRegex = GENERAL_ZIP_CODE_REGEX;
  }

  const zipcodeField = (
    <Controller
      name="addressInfo.zipcode"
      control={control}
      rules={{
        required: isCountryUsaOrCanada && zipcode.requiredError,
        pattern: zipcode.formatError
          ? {
              value: zipCodeRegex,
              message: `${zipcode.formatError}`,
            }
          : undefined,
      }}
      render={({ field }) => (
        <TextFormField
          {...field}
          type="text"
          label={zipcode.label}
          ariaLabel={zipcode.ariaLabel}
          className="m-input-field"
          maxLength={zipcode.maxLength}
          showErrorMessage={!!errors?.addressInfo?.zipcode?.message}
          errorMessage={errors?.addressInfo?.zipcode?.message}
          testId="zipcode"
          onChange={event => {
            field.onChange(event);
            clearErrors(field.name);
          }}
        />
      )}
    />
  );

  return (
    <StyledAddressInfo data-component-name="m-groups-AddressInfo" data-testid="groups-AddressInfo">
      <div className="t-subtitle-l pb-5">{title}</div>
      <div className="row">
        <div className="col-md-6 pb-4 px-0">{addressTypeOptions}</div>
      </div>
      <div className="row">
        <div className="col-12 pb-5">{companyNameField}</div>
      </div>
      <div className="row">
        <div className="col-md-6 pb-5">{addressLine1Field}</div>
        <div className="col-md-6 pb-5">{addressLine2Field}</div>
      </div>
      <div className="row">
        <div className="col-md-6 pb-5">{countryField}</div>
        <div className="col-md-6 pb-5">{cityField}</div>
      </div>
      <div className="row">
        <div className="col-md-6 pb-5 pb-md-0">{stateField}</div>
        <div className="col-md-6">{zipcodeField}</div>
      </div>
    </StyledAddressInfo>
  );
};
